/* You can add global styles to this file, and also import other style files */
.nuvo-tailwind {
  @tailwind components;
  @tailwind base;
  @tailwind utilities;
  @tailwind screens;

  * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
    outline: none;
    scrollbar-color: initial;
  }

  input,
  textarea {
    /* fixed: can typing on safari */
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  .loader {
    border: 16px solid rgba(92, 142, 242, 0.3);
    border-radius: 50%;
    border-top: 16px solid rgb(92 142 242);
    width: 100px;
    height: 100px;
    animation: spin 1.2s linear infinite;
    position: relative;
    margin: auto;
    top: 16px;
  }

  .loader::before {
    --rad: radial-gradient(circle 7.5px, #5c8ef2 99%, transparent 100%);
    background: var(--rad) left -17px top 0, var(--rad) right -17px top 0;
    background-size: 224% 100%;
    content: '';
    height: 19px;
    left: -4px;
    position: absolute;
    top: -4.9px;
    width: 111%;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  .circle-loader {
    border: 3px solid currentColor;
    border-radius: 50%;
    border-top: 3px solid currentColor;
    width: 20px;
    height: 20px;
    animation: spin 1.2s linear infinite;
    position: relative;
    margin: auto;
    display: inline-block;
  }

  @keyframes progress {
    100% {
      transform: translateX(100%);
    }
  }

  .translate-by-keyframe {
    animation: progress 1.5s linear forwards;
  }
}
