.nuvo-tailwind {
  .default-popper {
    z-index: 170 !important; /* // z-50 */
    @apply pointer-events-none rounded border py-3 px-4 text-xs text-gray-50 opacity-0;
  }

  .error-popper {
    @apply bg-salmon-500 border-salmon-500;
  }

  .warning-popper {
    @apply border-yellow-500 bg-yellow-500;
  }

  .multiple-popper {
    @apply bg-blue-dark-800 border-blue-dark-800;
  }

  #arrow-message-info,
  #arrow-message-info::before {
    position: absolute;
    width: 13px;
    height: 13px;
    background: inherit;
  }

  #arrow-message-info {
    visibility: hidden;
  }

  #arrow-message-info::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  [overflow-hide] {
    visibility: hidden !important;
    opacity: 0 !important;
    pointer-events: none;
    z-index: 100;
  }

  [overflow-hide] #arrow {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
}

.nuvo-tailwind {
  .remove-popper {
    z-index: 170 !important;
    @apply pointer-events-none rounded border p-4 text-xs text-gray-50 opacity-0;
  }
  .remove-info-popper {
    @apply bg-blue-dark-800 border-blue-dark-800;
  }
}
