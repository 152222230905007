/* nuvo-join-sheet-list-scroll Scrollbar */

.nuvo-join-sheet-list-scroll > div > .simplebar-track {
  background-color: #f4f4f4;
  border-radius: 60px;
  pointer-events: auto;
}

.nuvo-join-sheet-list-scroll
  > div
  > .simplebar-track
  > .simplebar-scrollbar::before {
  background-color: #b9b9b9;
  border-radius: 60px;
  opacity: 1 !important;
}

.nuvo-join-sheet-list-scroll > div > .simplebar-track.simplebar-vertical {
  width: 5px;
  transform: translateX(7px);
}

.nuvo-join-sheet-list-scroll
  > div
  > .simplebar-track.simplebar-vertical
  .simplebar-scrollbar::before {
  inset: 0;
}

.nuvo-join-sheet-list-scroll > div > .simplebar-track.simplebar-horizontal {
  height: 5px;
  transform: translateY(5px);
}

.nuvo-join-sheet-list-scroll
  > div
  > .simplebar-track.simplebar-horizontal
  .simplebar-scrollbar {
  top: 0px;
  height: 5px;
}

.nuvo-join-sheet-list-scroll
  > div
  .simplebar-track.simplebar-horizontal
  .simplebar-scrollbar::before {
  inset: 0;
}

/* nuvo-join-sheet-list-modal-scroll Scrollbar */

.nuvo-join-sheet-list-modal-scroll > div > .simplebar-track {
  background-color: #f4f4f4;
  border-radius: 60px;
  pointer-events: auto;
}

.nuvo-join-sheet-list-modal-scroll
  > div
  > .simplebar-track
  > .simplebar-scrollbar::before {
  background-color: #b9b9b9;
  border-radius: 60px;
  opacity: 1 !important;
}

.nuvo-join-sheet-list-modal-scroll > div > .simplebar-track.simplebar-vertical {
  width: 5px;
  transform: translateX(7px);
}

.nuvo-join-sheet-list-modal-scroll
  > div
  > .simplebar-track.simplebar-vertical
  .simplebar-scrollbar::before {
  inset: 0;
}

.nuvo-join-sheet-list-modal-scroll
  > div
  > .simplebar-track.simplebar-horizontal {
  height: 5px;
  transform: translateY(5px);
}

.nuvo-join-sheet-list-modal-scroll
  > div
  > .simplebar-track.simplebar-horizontal
  .simplebar-scrollbar {
  top: 0px;
  height: 5px;
}

.nuvo-join-sheet-list-modal-scroll
  > div
  .simplebar-track.simplebar-horizontal
  .simplebar-scrollbar::before {
  inset: 0;
}

/* nuvo-append-sheet-list-modal-scroll Scrollbar */

.nuvo-append-sheet-list-modal-scroll > div > .simplebar-track {
  background-color: #f4f4f4;
  border-radius: 60px;
  pointer-events: auto;
}

.nuvo-append-sheet-list-modal-scroll
  > div
  > .simplebar-track
  > .simplebar-scrollbar::before {
  background-color: #b9b9b9;
  border-radius: 60px;
  opacity: 1 !important;
}

.nuvo-append-sheet-list-modal-scroll
  > div
  > .simplebar-track.simplebar-vertical {
  width: 5px;
  transform: translateX(7px);
}

.nuvo-append-sheet-list-modal-scroll
  > div
  > .simplebar-track.simplebar-vertical
  .simplebar-scrollbar::before {
  inset: 0;
}

.nuvo-append-sheet-list-modal-scroll
  > div
  > .simplebar-track.simplebar-horizontal {
  height: 5px;
  transform: translateY(5px);
}

.nuvo-append-sheet-list-modal-scroll
  > div
  > .simplebar-track.simplebar-horizontal
  .simplebar-scrollbar {
  top: 0px;
  height: 5px;
}

.nuvo-append-sheet-list-modal-scroll
  > div
  .simplebar-track.simplebar-horizontal
  .simplebar-scrollbar::before {
  inset: 0;
}
