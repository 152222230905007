.nuvo-tailwind {
  .dropdown-scroll .simplebar-scrollbar {
    top: 0;
    height: 5px;
  }
  .dropdown-scroll .simplebar-scrollbar::before {
    inset: 0;
  }

  .dropdown-scroll .simplebar-track {
    background-color: #f4f4f4;
    border-radius: 60px;
    pointer-events: auto;
  }

  .dropdown-scroll .simplebar-track > .simplebar-scrollbar::before {
    background-color: #b9b9b9;
    border-radius: 60px;
    opacity: 1 !important;
  }

  .dropdown-scroll .simplebar-track.simplebar-vertical {
    width: 5px;
    top: 8px;
    bottom: 8px;
    transform: translateX(-3px);
  }

  .dropdown-scroll
    .simplebar-track.simplebar-vertical
    .simplebar-scrollbar::before {
    inset: 0;
  }

  .dropdown-scroll .simplebar-track.simplebar-horizontal {
    height: 5px;
    transform: translateY(5px);
  }

  .dropdown-scroll .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    top: 8px;
    height: 5px;
  }

  .dropdown-scroll
    .simplebar-track.simplebar-horizontal
    .simplebar-scrollbar::before {
    inset: 0;
  }
}
