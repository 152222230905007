.nuvo-tailwind {
  /* start dropdown editor */

  #dropdown-scroll-element-id::-webkit-scrollbar {
    @apply !h-1 !w-1;
  }

  #dropdown-scroll-element-id::-webkit-scrollbar-track {
    background-color: #dfe2e4;
    @apply rounded-medium !w-1 bg-clip-padding;
  }

  #dropdown-scroll-element-id::-webkit-scrollbar-thumb {
    @apply rounded-medium;
    background-color: #818b99;
    border: 0px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  #dropdown-scroll-element-id::-webkit-scrollbar {
    @apply !h-1 !w-1;
  }

  #dropdown-scroll-element-id::-webkit-scrollbar-track {
    background-color: #dfe2e4;
    @apply rounded-medium bg-clip-padding;
  }

  #dropdown-scroll-element-id::-webkit-scrollbar-thumb {
    @apply rounded-medium !bg-gray-300;
  }

  /* end dropdown editor */
}
