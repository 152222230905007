.sheet-preview__table {
  overflow: hidden;
  height: 100%;
}

.sheet-preview__container,
.sheet-preview__table,
.sheet-preview__table > div,
.sheet-preview__table .ht_master {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  min-height: 0;
}

.sheet-preview__container {
  position: relative;
}

.nuvo-tailwind .sheet-preview__table .ht_clone_left .wtHolder table {
  box-shadow: none;
}

.sheet-preview__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nuvo-tailwind .sheet-preview__modal {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 24px;
}
