.configure-form__body {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  overflow: auto;
}

.nuvo-sdk__configure-form {
  min-width: 860px;
}

.nuvo-sdk__configure-form .nuvo-tabs__tabs {
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--nuvo-white);
}

.nuvo-sdk__form-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.nuvo-sdk__form-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.nuvo-sdk__form-row {
  display: flex;
  gap: 16px;
}

.nuvo-sdk__form-col {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.nuvo-sdk__form-limitWidth {
  width: 394px;
}

.nuvo-sdk__form-sectionTitle {
  color: var(--nuvo-neutral-700);
}

.nuvo-sdk__codeArea textarea {
  font-family: monospace;
  box-shadow: none;
}

.nuvo-sdk__configuration-checkboxesCol {
  display: flex;
  flex-direction: column;
  gap: 16px;
  white-space: nowrap;
  width: 33.3%;
}

.nuvo-sdk__form-sectionContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.nuvo-sdk__form-clearPreviousMapping {
  display: flex;
  justify-content: flex-end;
}

.configure-form__resetButton {
  margin-right: auto;
}
